import { HttpHandlerFn, HttpHeaders, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { routing } from '@interceptor/mock-interceptor/services';
import { of, throwError } from 'rxjs';
const mocks: { [x: string]: any } = routing;

export const HttpMockRequestInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
    const method = request.method ? request.method : '';
    const currentMock = mocks[method] ? mocks[method] : '';

    var mockData: any = undefined;
    if (currentMock && currentMock.length > 0) {
        for (let x = 0; x < currentMock.length; x++) {
            const element = Object.assign(
                {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    status: 200,
                    url: null,
                    enabled: true,
                    json: {},
                    type: 'partial',
                },
                currentMock[x],
            );

            if (!element.enabled) {
                continue;
            } else if (element.type === 'partial' && request.url.indexOf(element.url) > -1) {
                window.console.info('%c [1] Loaded from json: ' + request.url, 'color: #FFaa00');
                window.console.info(element);
                mockData = element;
                x = currentMock.length;
            } else if (element.type === 'match' && request.url === element.url) {
                window.console.info('%c [2] Loaded from json : ' + request.url, 'color: #FFaa00');
                mockData = element;
                x = currentMock.length;
            }
        }
    }

    if (mockData != undefined) {
        if (mockData.status != 200) {
            return throwError(() => new Error(mockData.status + ''));
        }
        return of(
            new HttpResponse({
                status: mockData.status,
                statusText: 'OK',
                headers: new HttpHeaders(mockData.headers),
                body: JSON.stringify((mockData.json as any).default || {}),
                url: request.url,
            }),
        );
    }

    console.info('%c Loaded from http call: ' + request.url, 'color: #BA1126');

    return next(request);
};
