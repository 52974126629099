import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'highlightFilteredText',
})
export class HighlightFilteredTextPipe implements PipeTransform {
    transform(text: string, search: string | null): string {
        const searchValue: string = search ?? '';
        const escapedSearch = searchValue.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
        const pattern = escapedSearch
            .split(' ')
            .filter(t => t)
            .join('|');
        const regex = new RegExp(pattern, 'gi');
        return search ? text.replace(regex, word => `<span class="color-500">${word}</span>`) : text;
    }
}
