import { MockRoutes } from '@interceptor/mock-interceptor/mock.server.config';

var routes = new MockRoutes();
//routes.get('Users/me').respond(me);
//routes.get('Charity').respond(allCharities);
//routes.get('Petition').respond(allPetitions);
//routes.get('Meeting').respond(allMeetings);
//routes.get('Charity/users').respond(allUsers);

export const routing = routes.export();
