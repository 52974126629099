import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from '@env/environment';
import { UserService } from '@shared/services/user.service';

export const TokenInterceptor = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
    const url: string = request.url;
    const userService = inject(UserService);

    if (!url.startsWith(environment.apiEndpoint)) return next(request);

    if (
        url.indexOf(environment.apiEndpoint + '/Users/login') > -1 ||
        url.indexOf(environment.apiEndpoint + '/Users/forgotPassword') > -1 ||
        url.indexOf(environment.apiEndpoint + '/Users/register') > -1 ||
        url.indexOf(environment.apiEndpoint + '/Users/confirmEmail') > -1 ||
        url.indexOf(environment.apiEndpoint + '/Users/resendConfirmationEmail') > -1
    ) {
        return next(request);
    }

    request = request.clone({
        setHeaders: {
            Authorization: 'Bearer ' + userService.accessToken(),
        },
    });

    return next(request);
};
