import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'dummyLoadingArray',
})
export class DummyLoadingArrayPipe implements PipeTransform {
    transform(qty: number | undefined, min: number = 0, maxQty: number = 10): number[] {
        let length: number = qty ?? min;
        length = Math.max(length, min);
        length = Math.min(length, maxQty); // Ensure length does not exceed maxQty

        return new Array(length).fill(0);
    }
}
