import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

type date = 'hours' | 'day' | 'month' | 'day-month' | 'short';

@Pipe({
    standalone: true,
    name: 'localizedDate',
})
export class LocalizedDatePipe implements PipeTransform {
    private translateService: TranslateService = inject(TranslateService);

    transform(value?: string, format?: date): string {
        if (!value) return '';
        switch (format) {
            case 'hours':
                return new Date(value).toLocaleString(this.translateService.currentLang, {
                    hour: 'numeric',
                    minute: 'numeric',
                });
            case 'day':
                return new Date(value).toLocaleString(this.translateService.currentLang, {
                    day: 'numeric',
                });
            case 'month':
                return new Date(value).toLocaleString(this.translateService.currentLang, {
                    month: 'short',
                });
            case 'day-month':
                return new Date(value).toLocaleString(this.translateService.currentLang, {
                    day: 'numeric',
                    month: 'short',
                });
            case 'short':
                return new Date(value).toLocaleString(this.translateService.currentLang, {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                });
            default:
                return new Date(value).toLocaleString(this.translateService.currentLang, {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                });
        }
    }
}
