import { HttpBackend, HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
    InMemoryScrollingOptions,
    provideRouter,
    withComponentInputBinding,
    withHashLocation,
    withInMemoryScrolling,
    withViewTransitions,
} from '@angular/router';
import { HeadersInterceptor } from '@core/interceptor/headers-interceptor';
import { TokenInterceptor } from '@core/interceptor/token-interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { routes } from './app.routes';

import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { HttpMockRequestInterceptor } from '@interceptor/mock-interceptor/mock.interceptor';
import { LocalizedDatePipe } from '@shared/pipes';
import { MatPaginatorIntlDe } from '@shared/services/paginator-intl-de';
import { provideToastr } from 'ngx-toastr';

const scrollConfig: InMemoryScrollingOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'disabled',
};
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const MY_DATE_FORMAT = {
    parse: {
        dateInput: ['YYYY-MM-DD', 'DD.MM.YYYY', 'DDMMYYYY'], // this is how your date will be parsed from Input
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const appConfig: ApplicationConfig = {
    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'de-DE',
        },
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlDe },
        provideRouter(
            routes,
            withComponentInputBinding(),
            withViewTransitions(),
            withInMemoryScrolling(scrollConfig),
            withHashLocation(),
        ),
        provideAnimations(),
        provideHttpClient(withInterceptors([HeadersInterceptor, HttpMockRequestInterceptor, TokenInterceptor])),
        LocalizedDatePipe,
        importProvidersFrom(
            TranslateModule.forRoot({
                defaultLanguage: 'de',
                loader: {
                    provide: TranslateLoader,
                    useFactory: (httpHandler: HttpBackend) => {
                        return new TranslateHttpLoader(new HttpClient(httpHandler), './assets/i18n/');
                    },
                    deps: [HttpBackend],
                },
            }),
        ),
        provideAnimations(), // required animations providers
        provideToastr(), // Toastr providers
    ],
};
