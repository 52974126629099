class SingleMockRoute {
    storage = {
        url: null,
        json: {},
        enabled: true,
        type: 'partial',
        status: 200,
    };
    promise: Promise<any> | null = null;

    constructor(obj: object) {
        this.storage = Object.assign(
            {},
            {
                url: null,
                json: {},
                enabled: true,
                type: 'partial',
                status: 200,
            },
            obj,
        );
    }

    respond(jsonImportPromise: Promise<any> | object, code: number = 200): Promise<any> {
        return new Promise((resolve, reject) => {
            this.storage.status = code;
            if (jsonImportPromise instanceof Promise) {
                this.promise = jsonImportPromise;
                jsonImportPromise.then(r => {
                    this.storage.json = r.default;
                    resolve(true);
                });
            } else {
                this.storage.json = jsonImportPromise;
                resolve(true);
            }
        });
    }

    status(code: number) {
        this.storage.status = code;
    }

    export() {
        return this.storage;
    }
}
export class MockRoutes {
    routes: { [key: string]: { [key: string]: SingleMockRoute } } = {
        GET: {},
        POST: {},
        PUT: {},
        DELETE: {},
    };

    constructor() {}

    _method(method: string, defaultStatusCode: number, path: string) {
        this.routes[method][path] = new SingleMockRoute({
            url: path,
            status: defaultStatusCode,
        });
        return this.routes[method][path];
    }

    get(path: string) {
        return this._method('GET', 200, path);
    }

    post(path: string) {
        return this._method('POST', 201, path);
    }

    put(path: string) {
        return this._method('PUT', 201, path);
    }

    delete(path: string) {
        return this._method('DELETE', 204, path);
    }

    export() {
        var helper = (method: string) => {
            return Object.values(this.routes[method]).map((r: SingleMockRoute) => r.export());
        };
        var ret: { [key: string]: any } = {};
        Object.keys(this.routes).forEach(method => {
            ret[method] = helper(method);
        });
        return ret;
    }
}
